.tooltip {
    /* Existing tooltip styles */
    position: absolute;
    font-size: 14px !important;
    padding: 8px 16px !important;
    background-color: rgba(97, 97, 97, 0.9);
    color: white;
    border-radius: 4px;
    z-index: 1000; /* Ensure it's above other content */

    /* Triangle pointing up for tooltips shown below the target element */
    &.bottom::after {
        content: '';
        position: absolute;
        top: -5px;
        transform: translateX(-50%);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid rgba(97, 97, 97, 0.9);
    }

    /* Triangle pointing down for tooltips shown above the target element */
    &.top::before {
        content: '';
        position: absolute;
        bottom: -5px;
        transform: translateX(-50%);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid rgba(97, 97, 97, 0.9);
    }

    &.bottom::after,
    &.top::before {
        left: calc(50% + var(--triangle-offset, 0));
    }
}
