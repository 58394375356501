/* Modern CSS Reset 2024 */
* {
    text-trim: start end;
}

/*
 * Supports both light and dark mode color schemes based on user preferences.
 * Enhances readability with modern punctuation handling.
 */
html {
    hanging-punctuation: first last;
    color-scheme: dark light;
}

/*
 * Makes media elements responsive by ensuring they don't overflow their containers.
 * Aligns media elements vertically in the middle when used inline or within flex containers.
 * Applies an italic style to alternative text when images fail to load, enhancing readability.
 * Ensures background images cover the element fully without repeating.
 * Adds margin around shapes for advanced layout techniques.
 */
img,
picture,
svg,
video {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    font-style: italic;
    background-repeat: no-repeat;
    background-size: cover;
    shape-margin: 0.75rem;
}

/*
 * Balances text wrapping for headers, enhancing readability.
 * Limits paragraph width to 75 characters, a commonly recommended line length for readability.
 */
h1,
h2,
h3,
h4,
h5,
h6 {
    text-wrap: balance;
}

p {
    max-width: 75ch;
    text-wrap: pretty;
}

/*
 * Provides smooth scrolling for target elements when users do not prefer reduced motion.
 * Adds padding at the top for better visibility of target elements.
 */
@media (prefers-reduced-motion: no-preference) {
    :has(:target) {
        scroll-behavior: smooth;
        scroll-padding-top: 2rem;
    }
}
