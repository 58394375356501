ion-toast.notification::part(icon) {
    color: var(--button-color);
}

ion-toast.notification {
    --ion-safe-area-top: 1.5rem;
    --start: initial;
    --background: var(--ion-color-light);
    --color: var(--ion-color-dark);
    --button-color: var(--ion-color-dark);
    --ion-color-step-100: var(--ion-color-dark);
    --ion-background-color-rgb: var(--ion-color-dark-rgb);
    --border-style: solid;
    --border-width: 1px 1px 1px 5px;
    --border-radius: 4px;
    --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    --end: 1px;

    ionic-icon {
        color: var(--ion-color-dark);
    }

    border-right: 3px solid inherit;

    width: calc(100% - 2rem);
    max-width: 40vw;
    margin: 1rem;

    &.top-right {
        top: var(--ion-safe-area-top, 0);
        right: 0;
        left: auto;
    }

    &.error {
        --button-color: var(--ion-color-danger-shade);
        --ion-background-color-rgb: var(--ion-color-danger-rgb);
        --ion-color-step-100: var(--ion-color-danger-shade);
        --border-color: var(--ion-color-danger);
    }

    &.success {
        --button-color: var(--ion-color-success-shade);
        --ion-background-color-rgb: var(--ion-color-success-rgb);
        --ion-color-step-100: var(--ion-color-success-shade);
        --border-color: var(--ion-color-success);

        .toast-button {
            color: var(--ion-color-success-shade);
        }
    }

    &.info {
        --button-color: var(--ion-color-primary-shade);
        --ion-background-color-rgb: var(--ion-color-primary-rgb);
        --ion-color-step-100: var(--ion-color-primary-shade);
        --border-color: var(--ion-color-primary);

        .toast-button {
            color: var(--ion-color-primary-shade);
        }
    }

    &.warning {
        --button-color: var(--ion-color-warning-shade);
        --ion-background-color-rgb: var(--ion-color-warning-rgb);
        --ion-color-step-100: var(--ion-color-warning-shade);
        --border-color: var(--ion-color-warning);

        .toast-button {
            color: var(--ion-color-warning-shade);
        }
    }

    &.neutral {
        --button-color: var(--ion-color-medium-shade);
        --border-color: var(--ion-color-medium);

        .toast-button {
            color: var(--ion-color-medium-shade);
        }
    }
}

// Optional: Add animation for toast appearance
@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.notification {
    animation: slideInFromTop 0.3s ease-out;
}
