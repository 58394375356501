/*
 * Purpose: Custom overrides for Ionic components
 * This file contains style modifications to adjust the default Ionic component styles
 * to match our specific design requirements. It's included in the angular.json as ionic.overrides.scss.
 */

// Remove focus outline from ion-button
ion-button::part(native):focus {
    outline: none; // Override: Removes the default focus outline for better visual consistency
}

// Customize ion-input styles
.sc-ion-input-md-h {
    /* Overrides: Adjust highlight colors and font size for inputs */
    --highlight-color-focused: oklch(0% 0 0); // Override: Sets focus highlight color to black
    --highlight-color-valid: oklch(0% 0 0); // Override: Sets valid state highlight color to black

    font-size: var(--ion-font-size); // Override: Use custom font size variable
}

// Adjust floating label position for ion-input
.label-floating.sc-ion-input-md-h .label-text-wrapper.sc-ion-input-md {
    transform: translateY(40%) scale(0.75); // Override: Custom positioning for floating labels
}

// Additional ion-input customizations
ion-input {
    // Overrides: Custom color variables for input
    --ion-card-color: oklch(0% 0 0); // Override: Set text color to black
    --ion-color-step-100: oklch(0 0 0 / 0.1); // Override: Custom color step for consistency
    --ion-color-step-150: oklch(0 0 0 / 0.1); // Override: Custom color step for consistency
}

// Ensure consistent text color in inputs
.native-input.sc-ion-input-md {
    color: var(--ion-card-color); // Override: Apply custom text color to native input
}

// Remove text transform from ion-button
ion-button {
    text-transform: none; // Override: Prevent automatic uppercase transformation of button text
}
