@import './styles/components/tooltip.scss';

:root {
    --ion-color-primary: oklch(62% 0.2 250);
    --ion-color-primary-rgb: 37, 125, 247;
    --ion-color-primary-contrast: oklch(100% 0 0);
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: oklch(51% 0.18 250);
    --ion-color-primary-tint: oklch(61% 0.22 250);

    --ion-color-secondary: oklch(30% 0.05 260);
    --ion-color-secondary-rgb: 53, 58, 72;
    --ion-color-secondary-contrast: oklch(100% 0 0);
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: oklch(25% 0.04 260);
    --ion-color-secondary-tint: oklch(35% 0.06 260);

    --ion-color-tertiary: oklch(80% 0.15 180);
    --ion-color-tertiary-rgb: 45, 200, 184;
    --ion-color-tertiary-contrast: oklch(0% 0 0);
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: oklch(75% 0.14 180);
    --ion-color-tertiary-tint: oklch(85% 0.16 180);

    --ion-color-success: oklch(85% 0.15 140);
    --ion-color-success-rgb: 165, 214, 167;
    --ion-color-success-contrast: oklch(0% 0 0);
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: oklch(80% 0.14 140);
    --ion-color-success-tint: oklch(90% 0.16 140);

    --ion-color-warning: oklch(85% 0.15 80);
    --ion-color-warning-rgb: 255, 204, 128;
    --ion-color-warning-contrast: oklch(0% 0 0);
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: oklch(80% 0.14 80);
    --ion-color-warning-tint: oklch(90% 0.16 80);

    --ion-color-danger: oklch(65% 0.25 25);
    --ion-color-danger-rgb: 239, 83, 80;
    --ion-color-danger-contrast: oklch(100% 0 0);
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: oklch(60% 0.23 25);
    --ion-color-danger-tint: oklch(70% 0.27 25);

    --ion-color-light: oklch(100% 0 0);
    --ion-color-light-rgb: 255, 255, 255;
    --ion-color-light-contrast: oklch(0% 0 0);
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: oklch(93% 0 0);
    --ion-color-light-tint: oklch(100% 0 0);

    --ion-color-medium: oklch(75% 0.1 230);
    --ion-color-medium-rgb: 144, 202, 249;
    --ion-color-medium-contrast: oklch(0% 0 0);
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: oklch(70% 0.09 230);
    --ion-color-medium-tint: oklch(80% 0.11 230);

    --ion-color-dark: oklch(20% 0 0);
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: oklch(100% 0 0);
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: oklch(15% 0 0);
    --ion-color-dark-tint: oklch(25% 0 0);

    /* Heading sizes */
    --ion-heading-font-weight: 700;
    --ion-heading-line-height: 1.2;

    --h1-font-size: 2.5rem; /* 40px */
    --h2-font-size: 2rem; /* 32px */
    --h3-font-size: 1.75rem; /* 28px */
    --h4-font-size: 1.5rem; /* 24px */
    --h5-font-size: 1.25rem; /* 20px */
    --h6-font-size: 1rem; /* 16px */

    /* Paragraph and body text */
    --ion-font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    --ion-font-size: 1rem;
    --ion-line-height: 1.5;

    /* Other text elements */
    --ion-text-color: oklch(0% 0 0);
    --ion-text-color-rgb: 51, 51, 51;

    /* Dynamic font (if needed) */
    --ion-dynamic-font: var(--ion-default-dynamic-font);
}

html {
    font-size: 16px;
}

router-outlet {
    font-family: var(--ion-font-family), sans-serif;
    display: none;
}
