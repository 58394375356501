@import "../themes/ionic.globals";
@import "../themes/ionic.mixins";

// Text Alignment
// --------------------------------------------------
// Creates text alignment attributes based on screen size

@each $breakpoint in map-keys($screen-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $screen-breakpoints);

  @include media-breakpoint-up($breakpoint, $screen-breakpoints) {
    // Provide `.ion-text-{bp}` classes for aligning the text based
    // on the breakpoint
    .ion-text#{$infix}-center {
      text-align: center !important;
    }

    .ion-text#{$infix}-justify {
      text-align: justify !important;
    }

    .ion-text#{$infix}-start {
      text-align: start !important;
    }

    .ion-text#{$infix}-end {
      text-align: end !important;
    }

    .ion-text#{$infix}-left {
      text-align: left !important;
    }

    .ion-text#{$infix}-right {
      text-align: right !important;
    }

    .ion-text#{$infix}-nowrap {
      white-space: nowrap !important;
    }

    .ion-text#{$infix}-wrap {
      white-space: normal !important;
    }
  }
}
