@import "../themes/ionic.globals";
@import "../themes/ionic.mixins";

// Float Elements
// --------------------------------------------------
// Creates float classes based on screen size

@each $breakpoint in map-keys($screen-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $screen-breakpoints);

  @include media-breakpoint-up($breakpoint, $screen-breakpoints) {
    // Provide `.ion-float-{bp}-{side}` classes for floating the element based
    // on the breakpoint and side
    .ion-float#{$infix}-left {
      @include float(left, !important);
    }

    .ion-float#{$infix}-right {
      @include float(right, !important);
    }

    .ion-float#{$infix}-start {
      @include float(start, !important);
    }

    .ion-float#{$infix}-end {
      @include float(end, !important);
    }
  }
}
